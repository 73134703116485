<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-tabs
        dense
        indicator-color="orange"
        align="center"
        inline-label
        active-color="white"
        class="bg-blue-12 text-blue-11 shadow-2"
        v-model="tab"
      >
        <q-tab label="Status" name="status"></q-tab>
        <q-tab label="Jumlah" name="jumlah"></q-tab>
      </q-tabs>
      <q-tab-panels v-model="tab">
        <q-tab-panel name="jumlah" class="q-pa-none">
          <q-card-section>
            <p class="text-center text-h6 text-grey-8">SMP PA</p>
            <q-markup-table
              dense
              flat
              bordered
              wrap-cells
              separator="cell"
              class="stickyTable"
              style="height:auto"
            >
              <thead>
                <tr>
                  <th>Tingkat</th>
                  <th>Jumlah Siswa</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr>
                  <td>7</td>
                  <td>{{ jumlah_smp_pa.t7 }}</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>{{ jumlah_smp_pa.t8 }}</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>{{ jumlah_smp_pa.t9 }}</td>
                </tr>
                <tr class="bg-indigo-2 text-bold">
                  <td>TOTAL</td>
                  <td>{{ jumlah_smp_pa.total }}</td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
          <q-card-section>
            <p class="text-center text-h6 text-grey-8">SMP PI</p>
            <q-markup-table
              dense
              flat
              bordered
              wrap-cells
              separator="cell"
              class="stickyTable"
              style="height:auto"
            >
              <thead>
                <tr>
                  <th>Tingkat</th>
                  <th>Jumlah Siswa</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr>
                  <td>7</td>
                  <td>{{ jumlah_smp_pi.t7 }}</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>{{ jumlah_smp_pi.t8 }}</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>{{ jumlah_smp_pi.t9 }}</td>
                </tr>
                <tr class="bg-indigo-2 text-bold">
                  <td>TOTAL</td>
                  <td>{{ jumlah_smp_pi.total }}</td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
          <q-card-section>
            <p class="text-center text-h6 text-grey-8">MA</p>
            <q-markup-table
              dense
              flat
              bordered
              wrap-cells
              separator="cell"
              class="stickyTable"
              style="height:auto"
            >
              <thead>
                <tr>
                  <th>Tingkat</th>
                  <th>Jumlah Siswa</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr>
                  <td>7</td>
                  <td>{{ jumlah_ma.t10 }}</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>{{ jumlah_ma.t11 }}</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>{{ jumlah_ma.t12 }}</td>
                </tr>
                <tr class="bg-indigo-2 text-bold">
                  <td>TOTAL</td>
                  <td>{{ jumlah_ma.total }}</td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
          <p class="text-center text-bold text-h6  q-mt-md">
            TOTAL SISWA : {{ grand }}
          </p>
        </q-tab-panel>
        <q-tab-panel name="status" class="q-pa-none">
          <q-card-section class="q-gutter-sm">
            <q-select
              :options="$LIST_JENJANG"
              v-model="selJenjang"
              outlined
              label="Jenjang"
              @input="getData"
            ></q-select>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section>
            <apexchart
              type="pie"
              :options="chartOptions"
              :series="series"
            ></apexchart>
            <p class="text-center text-h6 text-grey-8">TOP 3 Sekolah Asal</p>
            <q-markup-table
              dense
              flat
              bordered
              wrap-cells
              separator="cell"
              class="stickyTable"
              style="height:auto"
            >
              <thead>
                <tr>
                  <th>Sekolah Asal</th>
                  <th>Jumlah Siswa</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, i) in sekolah_asal" :key="i">
                  <td>{{ val.sekolah_asal }}</td>
                  <td
                    class="text-center"
                    @click="
                      $router.push(
                        `/statistiksiswalistasal/${selJenjang}/${val.sekolah_asal}`
                      )
                    "
                  >
                    {{ val.banyak }}
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section>
            <p class="text-center text-h6 text-bold" v-if="siswa_lanjutan[0]">
              Siswa Lanjutan Ar-Risalah : {{ siswa_lanjutan[0].banyak }} SISWA
            </p>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section>
            <p class="text-center text-h6 text-grey-8">Siswa Aktif</p>
            <q-markup-table
              dense
              flat
              bordered
              wrap-cells
              separator="cell"
              class="stickyTable"
              style="height:auto"
            >
              <thead>
                <tr>
                  <th>
                    Tahun
                  </th>
                  <th>
                    Jumlah Siswa
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, i) in siswa_aktif" :key="i">
                  <td class="text-center">{{ val.tahun }}</td>
                  <td class="text-center">{{ val.banyak }}</td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </div>
</template>

<script>
import General from "@/mixins/General";
export default {
  mixins: [General],
  data() {
    return {
      tab: "status",

      selJenjang: this.$route.params.jenjang,

      sekolah_asal: [],
      siswa_lanjutan: [],
      siswa_aktif: [],

      series: [44, 55, 13, 33],
      chartOptions: {
        labels: ["Apple", "Mango", "Orange", "Watermelon"],
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
          offsetX: 0,
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          },
          // style: {
          //   colors: ["#F44336", "#E91E63", "#9C27B0"],
          // },
        },

        chart: {
          type: "pie",
        },
      },

      jumlah_smp_pa: {
        t7: 0,
        t8: 0,
        t9: 0,
        total: 0,
      },
      jumlah_smp_pi: {
        t7: 0,
        t8: 0,
        t9: 0,
        total: 0,
      },
      jumlah_ma: {
        t10: 0,
        t11: 0,
        t12: 0,
        total: 0,
      },
      grand: 0,
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getData();
    await this.getJumlah();
    this.$q.loading.hide();
  },
  methods: {
    async getData() {
      let chartopt = this.MCopyObject(this.chartOptions);
      chartopt.labels = [];
      let tempSeries = [];

      let resp = await this.$http.get(
        `/statistik/siswa/getdata/${this.selJenjang}`
      );

      this.sekolah_asal = resp.data[0];
      for (let item of resp.data[0]) {
        chartopt.labels.push(item.sekolah_asal);
        tempSeries.push(parseInt(item.banyak));
      }
      this.chartOptions = chartopt;
      this.series = tempSeries;

      this.siswa_lanjutan = resp.data[1];
      this.siswa_aktif = resp.data[2];
    },
    async getJumlah() {
      let resp = await this.$http.get(`/statistik/siswa/getjumlah`);

      let temp = {
        t7: resp.data[0][0],
        t8: resp.data[0][1],
        t9: resp.data[0][2],
        total:
          parseInt(resp.data[0][0]) +
          parseInt(resp.data[0][1]) +
          parseInt(resp.data[0][2]),
      };
      this.jumlah_smp_pa = temp;

      temp = {
        t7: resp.data[1][0],
        t8: resp.data[1][1],
        t9: resp.data[1][2],
        total:
          parseInt(resp.data[1][0]) +
          parseInt(resp.data[1][1]) +
          parseInt(resp.data[1][2]),
      };
      this.jumlah_smp_pi = temp;

      temp = {
        t10: resp.data[2][0],
        t11: resp.data[2][1],
        t12: resp.data[2][2],
        total:
          parseInt(resp.data[2][0]) +
          parseInt(resp.data[2][1]) +
          parseInt(resp.data[2][2]),
      };
      this.jumlah_ma = temp;

      this.grand =
        this.jumlah_smp_pa.total +
        this.jumlah_smp_pi.total +
        this.jumlah_ma.total;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.scss";
</style>
